import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

function impressum() {
  return (
    <Layout>
      <SEO title="Impressum" robots="noindex" />
      <div className="container legal">
        <h1>Impressum</h1>
        <p>
          <br />
          Innenarchitektur Schmitter
          <br />
          Volksdorfer Damm 272
          <br />
          22395 Hamburg
          <br />
          <br />
          Telefon: +49 40 60566090
          <br />
          E-Mail: info@schmitter-design.de
          <br />
          www.innenarchitektur-schmitter.de
          <br />
          Vertreten durch: Jörg Schmitter
          <br />
          <br />
          Jörg Schmitter ist Mitglied der Hamburgischen Architektenkammer, dort
          mit der Berufsbezeichnung Innenarchitekt (verliehen in der
          Bundesrepublik Deutschland) in die Architektenliste eingetragen und
          demnach berechtigt, eben diese Berufsbezeichnung zu führen.
          <br />
          <br />
          Zuständige Behörde/Aufsichtsbehörde:
          <br />
          Hamburgische Architektenkammer
          <br />
          Grindelhof 40
          <br />
          20146 Hamburg
          <br />
          Telefon: +49 40 441841-0
          <br />
          Telefax: +49 40 441841-44
          <br />
          Email: info@akhh.de
          <br />
          Internet: www.akhh.de
          <br />
          <br />
          Berufsrechtliche Regelungen, wie insbesondere das Hamburgische
          Architektengesetz und die Ehrenordnung der Hamburgischen
          Architektenkammer, sind einsehbar unter: www.recht.akhh.de
          <br />
          <br />
          Umsatzsteuer-ID: <br />
          Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz:
          <br />
          DE118313224
          <br />
          <br />
          Jörg Schmitter, Diplom Designer Innenarchitekt Architektenkammer
          Hamburg (AKHH), HAK 4330
          <br />
          Hamburger Architektenkammer
          <br />
          Regelungen: https://akhh.de
          <br />
          <br />
        </p>
        <h3>1. Warnhinweis zu Inhalten</h3>
        <p>
          Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden
          mit größtmöglicher Sorgfalt erstellt. Der Anbieter dieser Webseite
          übernimmt jedoch keine Gewähr für die Richtigkeit und Aktualität der
          bereitgestellten kostenlosen und frei zugänglichen journalistischen
          Ratgeber und Nachrichten. Namentlich gekennzeichnete Beiträge geben
          die Meinung des jeweiligen Autors und nicht immer die Meinung des
          Anbieters wieder. Allein durch den Aufruf der kostenlosen und frei
          zugänglichen Inhalte kommt keinerlei Vertragsverhältnis zwischen dem
          Nutzer und dem Anbieter zustande, insoweit fehlt es am
          Rechtsbindungswillen des Anbieters.
        </p>
        <h3>2. Externe Links</h3>
        <p>
          Diese Website enthält Verknüpfungen zu Websites Dritter ("externe
          Links"). Diese Websites unterliegen der Haftung der jeweiligen
          Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der
          externen Links die fremden Inhalte daraufhin überprüft, ob etwaige
          Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße
          ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und
          zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten. Das
          Setzen von externen Links bedeutet nicht, dass sich der Anbieter die
          hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine
          ständige Kontrolle der externen Links ist für den Anbieter ohne
          konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von
          Rechtsverstößen werden jedoch derartige externe Links unverzüglich
          gelöscht.
        </p>
        <h3>3. Urheber- und Leistungsschutzrechte</h3>
        <p>
          Die auf dieser Website veröffentlichten Inhalte unterliegen dem
          deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen
          Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf
          der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen
          Rechteinhabers. Dies gilt insbesondere für Vervielfältigung,
          Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe
          von Inhalten in Datenbanken oder anderen elektronischen Medien und
          Systemen. Inhalte und Rechte Dritter sind dabei als solche
          gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe
          einzelner Inhalte oder kompletter Seiten ist nicht gestattet und
          strafbar. Lediglich die Herstellung von Kopien und Downloads für den
          persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.
          Die Darstellung dieser Website in fremden Frames ist nur mit
          schriftlicher Erlaubnis zulässig.
        </p>
        <h3>4. Besondere Nutzungsbedingungen</h3>
        <p>
          Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von
          den vorgenannten Paragraphen abweichen, wird an entsprechender Stelle
          ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen
          Einzelfall die besonderen Nutzungsbedingungen.
        </p>
      </div>
    </Layout>
  )
}

export default impressum
